import axios from 'axios';
import { toast } from 'react-toastify';

export const SERVER = process.env.REACT_APP_URL;

export const axiosInstance = axios.create({
  baseURL: SERVER,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.data) {
      toast.error(error.response.data)
    }
  }
);
