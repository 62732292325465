export const countries = ['be', 'ch', 'fr', 'at', 'gr', 'no', 'nl', 'pt', 'it', 'se', 'cy', 'cz', 'rs', 'hu', 'de', 'za', 'ro', 'ua',];


export const lngOptions = [
  { key: 'en', title: 'English' },
  { key: 'fr', title: 'Français' },
  { key: 'de', title: 'Deutsch' },
  { key: 'no', title: 'Norsk' },
  { key: 'nl', title: 'Nederlands' },
];

export const showFlagsNumber = 12;


export const serviceContacts = {
  FR: { phone: '0975182032', email: 'france@mob.support' },
}