const countries = {
  BE: {
    prefix: '32',
    national: '0',
    avail: ['04'],
    placeholder: '04',
    maxLength: 9,
  },
  CH: {
    prefix: '41',
    national: '0',
    avail: ['07'],
    placeholder: '07',
    maxLength: 9,
  },
  FR: null,
  // {
  //   prefix: '+33',
  //   national: '0',
  //   avail: ['07'],
  //   placeholder: '07',
  //   maxLength: 9,
  // },
  AT: {
    prefix: '43',
    national: '0',
    avail: ['06'],
    placeholder: '06',
    maxLength: 11,
  },
  GR: {
    prefix: '30',
    national: '',
    avail: ['69'],
    placeholder: '69',
    maxLength: 10,
  },
  NO: {
    prefix: '47',
    national: '0',
    avail: ['09', '04'],
    placeholder: '09',
    maxLength: 8,
  },
  NL: {
    prefix: '31',
    national: '0',
    avail: ['06', '09'],
    placeholder: '06',
    maxLength: 9,
  },
  PT: {
    prefix: '351',
    national: '0',
    avail: ['09'],
    placeholder: '09',
    maxLength: 9,
  },
  IT: {
    prefix: '39',
    national: '0',
    avail: ['03'],
    placeholder: '03',
    maxLength: 13,
  },
  SE: {
    prefix: '46',
    national: '0',
    avail: ['07'],
    placeholder: '07',
    maxLength: 10,
  },
  CY: {
    prefix: '357',
    national: '',
    avail: ['09'],
    placeholder: '09',
    maxLength: 8,
  },
  CZ: {
    prefix: '420',
    national: '',
    avail: ['6', '7', '9'],
    placeholder: '6',
    maxLength: 9,
  },
  RS: {
    prefix: '381',
    national: '0',
    avail: ['01'],
    placeholder: '01',
    maxLength: 9,
  },
  HU: {
    prefix: '36',
    national: '06',
    avail: ['06'],
    placeholder: '06',
    maxLength: 9,
  },
  DE: {
    prefix: '49',
    national: '0',
    avail: ['01'],
    placeholder: '01',
    maxLength: 11,
  },
  ZA: {
    prefix: '27',
    national: '0',
    avail: ['07', '06', '08'],
    placeholder: '07',
    maxLength: 9,
  },
  RO: {
    prefix: '40',
    national: '0',
    avail: ['09'],
    placeholder: '09',
    maxLength: 10,
  },
  UA: {
    prefix: '380',
    national: '0',
    avail: ['05', '06', '07', '09'],
    placeholder: '0',
    maxLength: 9,
  }
};

export default countries;