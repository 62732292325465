const palette = {
  primary: {
    main: '#3ba0ff',
  },
  secondary: {
    main: '#8b8b8b',
  },
  text: {
    main: '#3a3a40',
  }
};

export default palette;