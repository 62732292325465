import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from 'react-redux';

import { Typography, Box, TextField, Button, } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom'
import { CircleFlag } from 'react-circle-flags'
import { formMask } from '../../helpers/utils';
import countries from '../../helpers/countries';
import DeregisterInfo from './DeregisterInfo';
import { getPin } from '../../redux/middlewares';


const CountryScreen = () => {
  const { t } = useTranslation();
  let { code } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const getErrorMessage = () => `${t('numberShouldStartWith')} ${countries[code].avail.join(' ')}`;
  const onChange = (value) => setRecaptcha(value);
  const onChangePhone = (val) => {
    if (!isNaN(val)) {
      if (val && val.length >= countries[code].avail[0].length) {
        const curCode = val.slice(0, [countries[code].avail[0].length]);
        setError(!countries[code].avail.includes(curCode));
      } else {
        setError(false);
      }
      setPhone(val.trim());
    }
  };
  const handleSend = () => {
    setLoading(true);
    dispatch(getPin(phone, code, recaptcha))
      .then((res) => {
        const code = res.data?.cause?.code;
        if (code === 5) {
          navigate('/email')
        } else if (code === 3) {
          navigate('/no-subscriptions')
        } else if (code === 0) {
          navigate('/pincode')
        }
      })
      .catch(() => setLoading(false))
  };
  return (
    <Box display='flex' flexDirection='column' width='100%' style={{ textAlign: 'center' }}>
      <Box pt={2}>
        <CircleFlag countryCode={code.toLowerCase()} height="47" style={{ pointerEvents: 'none' }} />

        <Typography variant='body1'
        >{`${t('chosenCountry')} ${t(code.toLowerCase())}`}
        </Typography>
      </Box>
      {countries[code] ?
        <>
          <Box pt={2}>
            <Typography variant='h3' style={{ marginBottom: '24px' }}
            >{`2. ${t('typePhone')}`}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' style={{ margin: 'auto', maxWidth: 'fit-content' }}>
            <TextField
              value={phone}
              error={error}
              helperText={error ? getErrorMessage() : ''}
              onChange={(e) => onChangePhone(e.target.value)}
              inputProps={{ maxLength: countries[code].maxLength + countries[code].national.length, minLength: 7 }}
              variant="outlined"
              style={{ marginBottom: '14px' }}
              placeholder={formMask(code)}
            />
            <ReCAPTCHA
              onExpired={() => onChange(null)}
              onChange={onChange}
              sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
            />
            <Box pt={2} width={1}>
              <Button
                disabled={phone.length < 7 || error || !recaptcha || loading}
                onClick={handleSend}
                size='large'
                sx={{ width: '100%', color: '#ffffff' }}
                variant="contained"
              >
                {t('send')}
              </Button>
            </Box>
          </Box>
        </> : <DeregisterInfo />}
      <Box pt='46px'>
        <Typography variant='body2' color='secondary'
        >{t('chooseAnotherCountry')}
        </Typography>
      </Box>
    </Box >
  )
}

export default CountryScreen;
