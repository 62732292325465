const messages = {
  deRegister: 'Deregistration portal',
  deRegisterSummery: 'Here you can de-register from active subscriptions',
  selectCountry: 'Select your country',
  chosenCountry: 'Chosen country',
  typePhone: 'Type your phone number',
  chooseAnotherCountry: 'If you want to choose another country',
  backToTheStart: 'Back to the start',
  send: 'Send',
  enterPin: 'Enter PIN code received from SMS',
  newPinCode: 'New PIN code',
  selectSubscriptionsToDeregister: 'Select subscriptions you want to deregister and click unsubscribe',
  unsubscribe: 'Unsubscribe',
  deregisterFromActive: 'To deregister from active subscriptions, you may call the following number',
  orSendEmailTo: 'or send an email to',
  noSubscriptions: 'You have no subscriptions',
  requestToCancel: 'Your request to cancel subscription was sent',
  numberShouldStartWith: 'Number should start with',
  updatePin: 'The pin code has been sent, you can request a new one in 1 min',
  typeYourEmail: 'Type your email address below',
  pinCantBeSent: "PIN can't be sent to your phone by SMS but you can receive it on your e-mail address",
  enterPinFromEmail: 'Enter received PIN code from your email address',
};

export default messages;