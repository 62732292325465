const messages = {
  deRegister: 'Abmeldungsportal',
  deRegisterSummery: 'Hier können Sie sich von aktiven Abonnements abmelden',
  selectCountry: 'Wählen Sie bitte Ihr Land aus',
  chosenCountry: 'Ausgewähltes Land',
  typePhone: 'Geben Sie Ihre Telefonnummer ein',
  chooseAnotherCountry: 'Falls Sie ein anderes Land wählen möchten',
  backToTheStart: 'Zurück zum Anfang',
  send: 'Senden',
  enterPin: 'Geben Sie den per SMS erhaltenen',
  newPinCode: 'Neuer PIN-Code',
  selectSubscriptionsToDeregister: 'Wählen Sie Abonnements aus, die Sie abmelden möchten, und klicken Sie auf Abmelden',
  unsubscribe: 'Abmelden',
  deregisterFromActive: 'Um sich von aktiven Abonnements abzumelden, können Sie die folgende Nummer anrufen',
  orSendEmailTo: 'oder senden Sie eine E-Mail an',
  noSubscriptions: 'Sie haben keine Abonnements.',
  requestToCancel: 'Ihre Anfrage zur Abonnement Kündigung wurde gesendet',
  numberShouldStartWith: 'Ihre Nummer sollte beginnen mit',
  updatePin: 'Der Pin-Code wurde gesendet. Sie können in 1 Minute einen neuen anfordern',
  typeYourEmail: 'Geben Sie unten Ihre E-Mail-Adresse ein',
  pinCantBeSent: 'Der PIN kann nicht per SMS an Ihr Handy gesendet werden, Sie können ihn unter Ihrer E-Mail Adresse empfangen',
  enterPinFromEmail: 'Geben Sie den von Ihrer E-Mail-Adresse erhaltenen PIN-Code ein',
};

export default messages;