import React from 'react';
import { Button, Drawer, Box, Typography } from '@mui/material';
import { Close, KeyboardArrowDown } from '@mui/icons-material';
import { lngOptions } from '../../helpers/constants';
import { setLanguage, getCurLanguage } from '../../localization';
const SelectLanguage = () => {
  const [open, setOpen] = React.useState(false);

  const drawerContext = () => {
    const curLang = getCurLanguage();
    return lngOptions.map((opt) =>
      <Box p='10px' style={{ cursor: 'pointer' }} key={opt.key} onClick={() => handleSelect(opt.key)}>
        <Typography sx={{ width: 'fit-content' }} variant='body2' color={curLang === opt.title ? 'text' : 'secondary'}>
          {opt.title}
        </Typography>
      </Box>
    )
  }
  const handleSelect = (key) => {
    setLanguage(key);
    setOpen(false);
  };
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };
  return (
    <Box p='16px'>
      <Button
        size='large'
        sx={{ textTransform: 'none' }}
        color='secondary'
        onClick={() => setOpen(true)}
        endIcon={<KeyboardArrowDown />}
      >
        {getCurLanguage()}
      </Button>

      <Drawer
        anchor='top'
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Box style={{ position: 'relative' }} p='18px' display='flex' flexDirection='column' alignItems='center'>
          {drawerContext()}
          <Close color='secondary' onClick={() => setOpen(false)} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
        </Box>
      </Drawer>

    </Box>
  )
}

export default SelectLanguage;