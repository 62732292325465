import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import EmailScreen from '../../screens/EmailScreen';
import CountryScreen from '../../screens/CountryScreen';
import PincodeScreen from '../../screens/PincodeScreen';
import SubscriptionsScreen from '../../screens/SubscriptionsScreen';
import ReactGA from 'react-ga4';
import NoSubscriptionsScreen from '../../screens/NoSubscriptionsScreen';
import CancelSubscriptionScreen from '../../screens/CancelSubscriptionScreen';

import StartScreen from '../../screens/StartScreen'
import Wrapper from '../Wrapper';

const RoutesComponent = () => {
  let location = useLocation();
  const items = useSelector(({ session: { items } }) => items);
  const phone = useSelector(({ session: { phone } }) => phone);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location?.pathname });
  }, [location]);

  return (
    <Routes>
      <Route element={<Wrapper />}>
        <Route path='/' element={<StartScreen />} />
        <Route path='/:code' element={<CountryScreen />} />
        <Route path='/email' element={phone ? <EmailScreen /> : <Navigate to="/" replace />} />
        <Route path='/pincode' element={phone ? <PincodeScreen /> : <Navigate to="/" replace />} />
        <Route path='/no-subscriptions' element={<NoSubscriptionsScreen />} />
        <Route path='/deregistrated' element={<CancelSubscriptionScreen />} />
        <Route path='/subscriptions' element={items.length > 0 ? <SubscriptionsScreen /> : <Navigate to="/" replace />} />
      </Route>
    </Routes>
  )
};


export default RoutesComponent;
