const messages = {
  deRegister: 'Portail de désinscription',
  deRegisterSummery: 'Vous pouvez vous désinscrire de vos souscriptions sur ce site',
  selectCountry: 'Séléctionnez votre pays',
  chosenCountry: 'Pays sélectionné',
  typePhone: 'Entrez votre numéro de téléphone',
  chooseAnotherCountry: 'Si vous désirez choisir un autre pays',
  backToTheStart: 'Redémarrer',
  send: 'Envoyer',
  enterPin: 'Entrez le pin code reçu par SMS',
  newPinCode: 'Nouveau code PIN',
  selectSubscriptionsToDeregister: 'Selectionnez les souscriptions à désinscrire et clickez Désinscrire',
  unsubscribe: 'Désinscrire',
  deregisterFromActive: 'Pour vous désinscrire des abonnements actifs, vous pouvez appeler le',
  orSendEmailTo: 'ou envoyer un e-mail à',
  noSubscriptions: "Vous n'avez aucune souscriptions actives",
  requestToCancel: 'Votre requête de désinscription a été prise en compte',
  numberShouldStartWith: 'Votre numéro doit commencer avec',
  updatePin: 'Le pin code a été envoyé, vous pouvez en demandez un nouveau dans 1 min',
  typeYourEmail: 'Tapez votre adresse électronique ci-dessous',
  pinCantBeSent: 'Le code PIN ne peut pas être envoyé à votre téléphone par SMS, mais vous pouvez le recevoir sur votre adresse e-mail',
  enterPinFromEmail:'Veuillez entrer le code PIN reçu par courriel',
};

export default messages;