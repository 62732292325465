import { actions } from './slices';
import api from '../api';
import { msisdnFormating } from '../helpers/utils';

export const getPin = (msisdn, code, recaptcha) => async (dispatch) => {
  const formatedMsisdn = msisdnFormating(msisdn, code);
  try {
    dispatch(actions.setPhone(formatedMsisdn));

    return api.getPin(formatedMsisdn, recaptcha);

  }
  catch {
    console.log('catch')
  }
};

export const getPinOnEmail = (msisdn, email) => async (dispatch) => {
  try {
    dispatch(actions.setEmail(email));

    return api.getNewPinOnEmail(msisdn, email);

  }
  catch {
    console.log('catch')
  }
};

export const getSubscriptions = (msisdn, pin) => async (dispatch) => {
  try {
    let result = await api.getSubscriptions(msisdn, pin);
    dispatch(actions.setItems(result.data));
    if (result.data) {
      return Promise.resolve(true);
    }

  }
  catch {
    console.log('catch')
  }
};



