import React from 'react';
import { Box, Typography, Grid, } from '@mui/material';
import { CircleFlag } from 'react-circle-flags'
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const FlagItem = ({ code }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${code.toUpperCase()}`)
  }
  return (
    <Grid item xs={4} style={{ textAlign: 'center' }}>
      <Box pb='10px' onClick={handleClick} style={{ cursor: 'pointer', textAlign: 'center', margin: 'auto' }}>
        <CircleFlag countryCode={code} height="47" style={{ pointerEvents: 'none' }} />

        <Typography color='secondary' variant='body2'>
          {t(code)}
        </Typography>
      </Box>
    </Grid>
  )
}

export default FlagItem;