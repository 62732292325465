import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { countries, showFlagsNumber } from '../../helpers/constants';
import FlagItem from './FlagItem';

const Flags = () => {
  const [showMore, serShowMore] = useState(false);
  const getArrayForMapping = () => showMore ? countries : countries.slice(0, showFlagsNumber)
  return (
    <Box width='100%'>
      <Grid container>
        {getArrayForMapping().map((country) =>
          <FlagItem code={country} key={country} />
        )}
        <Grid item xs={12} style={{ textAlign: 'center', width: '100%', padding: '16px' }}>
          {!showMore ? <ExpandMore onClick={() => serShowMore(true)} fontSize='large' color='secondary' style={{ cursor: 'pointer' }} /> :
            <ExpandLess onClick={() => serShowMore(false)} fontSize='large' color='secondary' style={{ cursor: 'pointer' }} />
          }
        </Grid>
      </Grid>
    </Box>
  )
}

export default Flags;