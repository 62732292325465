import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import { Typography, Box, TextField, Button } from '@mui/material';
import { useTranslation } from "react-i18next";
import { getSubscriptions } from '../../redux/middlewares';
import api from '../../api';

const PincodeScreen = () => {
  const { t } = useTranslation();
  const [pin, setPin] = useState('');
  const [newPin, setNewPin] = useState(false);
  const dispatch = useDispatch();
  const phone = useSelector(({ session: { phone } }) => phone);
  const email = useSelector(({ session: { email } }) => email);
  const getTranslation = () => {
    const text = email ? t('enterPinFromEmail') : t('enterPin');
    return `3. ${text}`;
  };

  const navigate = useNavigate();
  const onChange = (val) => {
    if (!isNaN(val)) {

      setPin(val.trim());
    }
  };
  const handleGetNewPin = () => {
    api.getNewPin(phone).then((res) => {
      const code = res.data?.cause?.code;
      if (code === 5) {
        navigate('/email')
      } else if (code === 3) {
        navigate('/no-subscriptions')
      } else if (code === 0) {
        setTimeout(() => { setNewPin(true) }, 60000);
      }
    });
    setNewPin(false)
  };
  useEffect(() => {
    if (!email) {
      setTimeout(() => {
        if (setNewPin) { setNewPin(true); }
      }, 60000);
    }
  }, [])
  const handleSubmit = () => {

    dispatch(getSubscriptions(phone, pin)).then((res) => {
      if (res) {
        navigate('/subscriptions')
      }
    })
  }
  return (
    <Box display='flex' flexDirection='column' width='100%' style={{ textAlign: 'center' }}>
      <Box pt={2}>
        <Typography variant='h3' style={{ marginBottom: '24px' }}
        >{getTranslation()}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' style={{ margin: 'auto', width: '264px' }}>
        <TextField
          value={pin}
          variant="outlined"
          style={{ marginBottom: '14px' }}
          onChange={(e) => onChange(e.target.value)}
        />
        <Box pt={2} width={1}>
          <Button
            disabled={pin.length !== 4}
            size='large'
            onClick={handleSubmit}
            sx={{ width: '100%', color: '#ffffff' }}
            variant="contained">
            {t('send')}
          </Button>
        </Box>
        {!email && <Box pt={2} width={1}>
          <Button
            onClick={handleGetNewPin}
            disabled={!newPin}
            size='large'
            sx={{ width: '100%' }}
            color='secondary'
            variant='outlined'>
            {t('newPinCode')}
          </Button>
          <Box pt={2} width={1}>
            {!newPin &&
              <Typography variant='body2' color='secondary'>{t('updatePin')}</Typography>
            }
          </Box>
        </Box>}
      </Box>
    </Box >
  )
}

export default PincodeScreen;
