import { en, fr, de, no, nl } from './localizations';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { lngOptions } from './helpers/constants';
import { setItem, getItem } from './helpers/localStorage';


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: { ...en } },
      fr: { translation: { ...fr } },
      de: { translation: { ...de } },
      no: { translation: { ...no } },
      nl: { translation: { ...nl } },
    },
    lng: getItem('lng') || "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false 
    }
  });




export const setLanguage = (lng) => {
  setItem('lng', lng);
  i18n.changeLanguage(lng);
}

export const getCurLanguage = () => lngOptions.filter((it) => it.key === i18n.language)[0].title;


