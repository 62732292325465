const typography = {
  h1: {
    fontSize: '28px',
    fontWeight: 'bold',
    lineHeight: '30px',
  },
  h3: {
    fontSize: '22px',
    lineHeight: '30px',
  },
  body1: {
    fontSize: '16px',
  },
  body2: {
    fontSize: '14px',
  },
  allVariants: {
    fontSize: '14px',
    color: '#3a3a40',
  },
};

export default typography;