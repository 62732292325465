import countries from '../helpers/countries';

export const formMask = (code) => {
  const sign = 'X';
  return `${countries[code].placeholder}${sign.repeat(countries[code].maxLength - countries[code].placeholder.length)}`;
};

export const msisdnFormating = (phone, code) => {
  const value = countries[code].national?.length ?
    phone.slice(countries[code].national.length) : phone;
  return `${countries[code].prefix}${value}`

};