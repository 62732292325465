const messages = {
  deRegister: 'Avbestillingsportal',
  deRegisterSummery: 'Her kan du avbestille fra aktive abonnementer',
  selectCountry: 'Velg ditt land',
  chosenCountry: 'Utvalgt land',
  typePhone: 'Skriv inn ditt telefonnummer',
  chooseAnotherCountry: 'Hvis du ønsker å velge et annet land',
  backToTheStart: 'Gå tilbake til starten',
  send: 'Sende',
  enterPin: 'Skriv inn PIN- koden du fikk via SMS',
  newPinCode: 'Ny PIN kode',
  selectSubscriptionsToDeregister: 'Velg abonnementer som du ønsker å avbestille og klikk avbestill',
  unsubscribe: ' Avbestill',
  deregisterFromActive: 'For å avbestille aktive abonnementer, ring nummer',
  orSendEmailTo: 'eller send en e-post til',
  noSubscriptions: 'Du har ikke noe abonnement',
  requestToCancel: 'Ditt krav for å avbestille abonnement er sent',
  numberShouldStartWith: 'Nummeret ditt bør begynne med',
  updatePin: 'Pinkoden er sendt, du kan be om en ny på 1 min',
  typeYourEmail: 'Skriv inn din email adresse under',
  pinCantBeSent: 'PIN kan ikke være sent til ditt telefonnummer via sms, men du kan få den på din e-mail adresse',
  enterPinFromEmail: 'Skriv inn mottatt PIN-kode fra e-postadressen din',
};

export default messages;