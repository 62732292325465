import { axiosInstance } from './axiosInstance';

const api = {
  getPin(msisdn, recaptcha) {

    let url = `customer/subscription/check?msisdn=%2B${msisdn}`;
    return axiosInstance({
      method: 'post',
      url,
      data: {
        recaptcha: recaptcha,
      },
    });
  },
  getNewPinOnEmail(msisdn, email) {
    let url = `customer/email/pin?msisdn=%2B${msisdn}`;
    return axiosInstance({
      method: 'post',
      url,
      data: {
        email: email,
      },
    });
  },
  getNewPin(msisdn) {
    let url = `customer/update/pin?msisdn=%2B${msisdn}`;

    return axiosInstance({
      method: 'get',
      url,
    });
  },
  getSubscriptions(msisdn, pin) {
    let url = `customer/subscription/get?msisdn=%2B${msisdn}`;

    return axiosInstance({
      method: 'post',
      url,
      data: {
        pin,
      },
    });
  },
  deregistrationSubscription(msisdn, subscriptions) {
    let url = `customer/subscription/deregistration?msisdn=%2B${msisdn}`;

    return axiosInstance({
      method: 'post',
      url,
      data: {
        subscriptions
      },
    });
  },
};

export default api;



