import { createTheme } from '@mui/material/styles';
import typography from './typography'
import palette from './palette';

const theme = createTheme({
  palette,
  typography,
  components: {
  }

});
export default theme;