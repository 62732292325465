import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Typography, Box, } from '@mui/material';
import { useTranslation } from "react-i18next";
import send from '../../assets/icon-send.png'
import { actions } from '../../redux/slices';


const CancelSubscriptionScreen = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.reset());
  }, [dispatch]);
  
  return (
    <Box display='flex' flexDirection='column' style={{ textAlign: 'center', maxWidth: '264px' }} mx='auto' mt='80px' >
      <Box mb='48px'><img src={send} alt="send" style={{ width: '46px' }} /></Box>
      <Typography variant='h3' >
        {t('requestToCancel')}
      </Typography>
    </Box >
  )
}

export default CancelSubscriptionScreen;
