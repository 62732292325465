const countries = {
  ch: 'Switzerland',
  be: 'Belgium',
  fr: 'France',
  at: 'Austria',
  gr: 'Greece',
  no: 'Norway',
  nl: 'Netherlands',
  pt: 'Portugal',
  it: 'Italy',
  se: 'Sweden',
  cy: 'Cyprus',
  cz: 'Czech Republic',
  rs: 'Serbia',
  hu: 'Hungary',
  de: 'Germany',
  za: 'South Africa',
  ro: 'Romania',
  ua: 'Ukraine',
};

export default countries;