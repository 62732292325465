import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Typography, Box, Button, Checkbox } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
import api from '../../api';


const SubscriptionsScreen = () => {
  const { t } = useTranslation();
  const [checkItem, setCheckItem] = useState([]);
  const phone = useSelector(({ session: { phone } }) => phone);
  const navigate = useNavigate();

  const items = useSelector(({ session: { items } }) => items);

  const handleUnsubscribe = () => {

    const subscriptions = items.filter((it) => checkItem.includes(it.id));
    api.deregistrationSubscription(phone, subscriptions).then(() => {
      navigate('/deregistrated')
    });
  };

  return (
    <Box display='flex' flexDirection='column' width='100%' style={{ textAlign: 'center' }}>
      <Box pt='80px'>
        <Typography variant='h3' style={{ marginBottom: '24px' }}
        >{`4. ${t('selectSubscriptionsToDeregister')}`}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' style={{ margin: 'auto', maxWidth: '264px' }} >
        {items.map((item) =>
          <Box key={item.id} display='flex' pb={2} alignItems='baseline'>
            <Box>
              <Checkbox
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                checked={checkItem.includes(item.id)}
                onChange={(e) => {
                  let newChecked = [...checkItem];
                  if (e.target.checked) {
                    newChecked.push(item.id);
                  } else {
                    newChecked = checkItem.filter((it) => it !== item.id)
                  }
                  setCheckItem(newChecked);
                }} />
            </Box>
            <Typography variant='body2' color='secondary'>
              {item.name}
            </Typography>
          </Box>)}

        <Box pt={2} width={1}>
          <Button
            onClick={handleUnsubscribe}
            size='large'
            sx={{ width: '100%', color: '#ffffff' }}
            variant="contained"
          >{t('unsubscribe')}
          </Button>
        </Box>
      </Box>
    </Box >
  )
}

export default SubscriptionsScreen;
