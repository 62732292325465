import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import { Typography, Box, TextField, Button } from '@mui/material';
import { useTranslation } from "react-i18next";
import { getPinOnEmail } from '../../redux/middlewares';

const EmailScreen = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);

    const dispatch = useDispatch();
    const phone = useSelector(({ session: { phone } }) => phone);
    const navigate = useNavigate();

    const onChange = (val) => {
        const emailIsValid = (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(val);
        if (!emailIsValid && !error) {
            setError(true);
        } else if (emailIsValid && error) {
            setError(false);
        }
        setEmail(val);
    };
    
    const handleSubmit = () => {
        dispatch(getPinOnEmail(phone, email)).then((res) => {
            if (res) {
                navigate('/pincode')
            }
        })
    }
    return (
        <Box display='flex' flexDirection='column' width='100%' style={{ textAlign: 'center' }}>
            <Box pt={2}>
                <Typography variant='h3' style={{ marginBottom: '24px' }}
                >{`3. ${t('typeYourEmail')}`}
                </Typography>
            </Box>
            <Box p={2} width={1}>
                <Typography variant='body2'>{t('pinCantBeSent')}</Typography>
            </Box>
            <Box display='flex' flexDirection='column' style={{ margin: 'auto', width: '264px' }}>

                <TextField
                    value={email}
                    variant="outlined"
                    style={{ marginBottom: '14px' }}
                    onChange={(e) => onChange(e.target.value)}
                />
                <Box pt={2} width={1}>
                    <Button
                        disabled={!email || error}
                        size='large'
                        onClick={handleSubmit}
                        sx={{ width: '100%', color: '#ffffff' }}
                        variant="contained">
                        {t('send')}
                    </Button>
                </Box>
            </Box>
        </Box >
    )
}

export default EmailScreen;
