import React from 'react';
import { Typography, Box, } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom'
import { serviceContacts } from '../../helpers/constants';

const DeregisterInfo = () => {
  const { t } = useTranslation();
  let { code } = useParams();

  return (
    <Box display='flex' flexDirection='column' width='100%' style={{ textAlign: 'center' }} pt={2} >
      <Typography variant='h3' >
        {t('deregisterFromActive')}
      </Typography>
      <Typography variant='h3' color='primary'>
        {serviceContacts[code].phone}
      </Typography>
      <Typography variant='h3'
      >{t('orSendEmailTo')}
      </Typography>
      <Typography variant='h3' color='primary'>
        {serviceContacts[code].email}
      </Typography>
    </Box >
  )
}

export default DeregisterInfo;
