import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';

import SelectLanguage from '../../components/SelectLanguage';
import { Box, Typography } from '@mui/material';
import { actions } from '../../redux/slices';

function Wrapper() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Box display='flex' flexDirection='column' alignItems='center' style={{ maxWidth: '414px', margin: 'auto' }}>
      <SelectLanguage />
      <Typography variant='h1' style={{ paddingTop: '8px' }}
      >{t('deRegister')}
      </Typography>
      <Outlet />
      <Box pt='46px'>
        <Typography variant='body2' color='secondary'
          onClick={() => dispatch(actions.reset())}
          style={{ textDecoration: 'underline dotted' }}
          component={Link} to='..' relative='path'>{t('backToTheStart')}
        </Typography>
      </Box>
    </Box>
  );
}

export default Wrapper;
