const messages = {
  deRegister: 'Uitschrijvingsportaal',
  deRegisterSummery: 'Hier kunt u zich afmelden voor alle actieve abonnementen',
  selectCountry: 'Kies uw land',
  chosenCountry: 'Gekozen land',
  typePhone: 'Typ uw telefoonnummer in',
  chooseAnotherCountry: 'Als u een ander land wilt kiezen',
  backToTheStart: 'Terug naar homepagina',
  send: 'Verzenden',
  enterPin: 'Voer de PIN code die je via sms ontvangt in',
  newPinCode: 'Nieuwe PIN code',
  selectSubscriptionsToDeregister: 'Selecteer het abonnement dat u wilt opzeggen en klik op opzeggen',
  unsubscribe: 'Opzeggen',
  deregisterFromActive: 'Om je uit te schrijven van alle active abonnementen, kunt u het volgende nummer bellen',
  orSendEmailTo: 'of stuur dan email',
  noSubscriptions: 'U heeft geen abonnementen',
  requestToCancel: 'Uw verzoek om het abonnement te annuleren is verzonden',
  numberShouldStartWith: 'Je nummer moet beginnen met',
  updatePin: 'De pincode is verstuurd, binnen 1 minuut kun je een nieuwe aanvragen',
  typeYourEmail: 'Typ hieronder uw emailadres',
  pinCantBeSent: 'Het is niet mogelijk sms berichten met de PIN code te versturen naar uw telefoonnummer, maar u kunt deze wel op uw emailadres ontvangen',
  enterPinFromEmail:'Voer de ontvangen pincode van uw e-mailadres in',
};

export default messages;