import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from "@mui/material";
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './services/Routes';
import theme from './theme';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        < Routes />
        <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          pauseOnFocusLoss
        />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
