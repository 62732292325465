import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from "react-i18next";
import Flags from '../../components/Flags';


const StartScreen = () => {
  const { t } = useTranslation();
  return (
    <Box display='flex' flexDirection='column' width='100%' style={{ textAlign: 'center' }}>
      <Box pt={2}>
        <Typography variant='body1' style={{ marginBottom: '40px' }}
        >{t('deRegisterSummery')}
        </Typography>
        <Typography variant='h3' style={{ marginBottom: '24px' }}
        >{`1. ${t('selectCountry')}`}
        </Typography>
        <Flags />

      </Box>

    </Box>
  )
}

export default StartScreen;
