import { createSlice } from '@reduxjs/toolkit';

const sessionSlice = createSlice({
  name: 'session',
  initialState: { items: [], phone: '', email: '' },
  reducers: {
    setVerifyPin(store, action) {
      store.verifyPin = action.payload;
    },
    setPhone(store, action) {
      store.phone = action.payload;
    },
    setItems(store, action) {
      store.items = action.payload;
    },
    setEmail(store, action) {
      store.email = action.payload;
    },
    reset(store) {
      store.items = [];
      store.phone = '';
      store.email = '';
    }
  }
});

export const slices = {
  session: sessionSlice.reducer,
}

export const actions = sessionSlice.actions;

